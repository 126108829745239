<style lang="scss">
.live-detail {
  padding-top: 16px;
  width: 88vw;
  margin: 10px auto;
  box-sizing: border-box;
  height: 77.5vh;
  display: flex;
  align-items: center;

  .left {
    box-sizing: border-box;
    width: 78.35%;
    height: 100%;

    .live-msg {
      width: 100%;
      height: 80px;
      margin-bottom: 8px;
      background-color: #ffffff;
      display: flex;
      align-items: center;

      .man-img {
        width: 56px;
        height: 56px;
        box-sizing: border-box;
        margin-left: 24px;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .live-xx {
        .key {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          text-align: left;
        }

        .value {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          text-align: left;
        }
      }

      .jbxx {
        margin-left: auto;
        margin-right: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 16px;
          height: 16px;
        }

        a {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }

    .live-content {
      width: 100%;
      height: calc(100% - 152px);
      box-sizing: border-box;
      position: relative;
    }

    .no-video {
      background-color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }

    }

    .waterMark {
      position: absolute;
      width: 83px;
      height: 38px;
      background: url(../assets/img/logo-watermark@2x.png) no-repeat;
      background-size: 100%;
      left: 47%;
      top: 16px;
      z-index: 1000;
    }

    .gift-box {
      width: 100%;
      height: 64px;
      background-color: #ffffff;
      padding: 0 12px;
      display: flex;
      align-items: center;

      .coin {
        display: flex;
        align-items: center;

        .key {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }

        .value {
          margin-left: 12px;
          width: 64px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          background-color: #20A0FF;
          border-radius: 10px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          cursor: pointer;
        }
      }

      .gift {
        display: flex;
        align-items: center;
        margin-left: auto;

        .gift-item {
          width: 40px;
          height: 40px;
          margin-left: 12px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .right {
    width: 20.7%;
    height: 100%;
    margin-left: auto;

    .pl-title {
      width: 100%;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.02);

      .text {
        display: inline-block;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #20a0ff;
        height: 48px;
        line-height: 48px;
        box-sizing: border-box;
        border-bottom: 2px solid #20a0ff;
        margin: 0 auto;
      }
    }

    .pl-content {
      width: 100%;
      height: calc(100% - 103px);
      background-color: #ffffff;
      overflow: auto;
      padding-bottom: 10px;

      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 10px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
      }

      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #ebebeb;
      }

      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: #ffffff;
      }

      .item {
        display: flex;
        margin-top: 10px;
        margin-left: 16px;

        .key {
          text-align: left;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #20a0ff;
        }

        .value {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          text-align: left;

          &.yellow {
            color: #ff7607;
          }

          &.red {
            color: #f078b5;
          }

          &.gray {
            color: #333333;
          }
        }
      }
    }

    .line-box {
      height: 56px;
    }

    .no-pl {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin: 0 auto;
    }

    .send-pl {
      height: 44px;
      margin-top: 12px;
      width: 100%;

      .pl-input {
        height: 44px;

        input {
          height: 44px;
        }
      }

      .ivu-input-group-append {
        background-color: #20A0FF;
      }

      .send-btn {
        width: 53px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }

  .vcp-fullscreen-toggle {
    display: none !important;
  }
}

.coin-modal {
  .ivu-modal-footer {
    display: none;
  }

  .coin-title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 12px;
  }

  .coin-item {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
  }

  .coin-tip {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-top: 24px;
  }

  .recharge {
    position: absolute;
    right: 24px;
    bottom: 24px;
    width: 60px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: #20A0FF;
    border-radius: 2px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
  }
}

.gift-modal {
  .ivu-modal-footer {
    display: none;
  }

  .gift-title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 24px;
  }

  .gift-item {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
  }

  .gift-btn {
    margin-top: 32px;
    text-align: right;

    button {
      margin-left: 16px;
    }
  }

  .recharge-item {
    display: flex;
    align-items: center;

    .key {
      margin-right: 4px;
      display: block;
      width: 16px;
      height: 16px;
      background: url(../assets/img/warn@2x.png) no-repeat;
      background-size: 100%;
    }
  }
}
</style>
<template>
  <div class="live-detail">
    <div class="left">
      <div class="live-msg">
        <div class="man-img">
          <img v-if="!liveinfo.avatar" src="../assets/img/live-man.png" alt="" />
          <img v-if="liveinfo.avatar" :src="liveinfo.avatar" alt="" />
        </div>
        <div class="live-xx">
          <div class="key">{{ liveinfo.title }}</div>
          <div class="value">{{ liveinfo.describe }}</div>
        </div>
        <div class="jbxx">
          <img src="../assets/img/jb-icon.png" alt="" />
          <a href="https://jbts.mct.gov.cn/" target="_blank">举报</a>
        </div>
      </div>
      <div class="live-content" id="video-container" v-if="m3u8">
        <!-- <div class="waterMark"></div> -->
      </div>
      <div class="live-content no-video" v-if="!m3u8">
        <div class="text">目前暂无直播</div>
      </div>
      <!-- <div class="gift-box">
        <div class="coin">
          <div class="key" v-if="!teenage">当前帐户剩余 {{xb}} 微币</div>
          <div class="key" v-if="teenage">当前帐户剩余 0 微币</div>
          <div class="value" @click="getXbGz">微币规则</div>
        </div>
        <div class="gift">
          <div class="gift-item" @click="reward(5)">
            <img src="../assets/img/5coin@2x.png" alt="">
          </div>
          <div class="gift-item" @click="reward(10)">
            <img src="../assets/img/10coin@2x.png" alt="">
          </div>
          <div class="gift-item" @click="reward(20)">
            <img src="../assets/img/20coin@2x.png" alt="">
          </div>
          <div class="gift-item" @click="reward(50)">
            <img src="../assets/img/50coin@2x.png" alt="">
          </div>
          <div class="gift-item" @click="reward(100)">
            <img src="../assets/img/100coin@2x.png" alt="">
          </div>
        </div>
      </div> -->
    </div>
    <div class="right">
      <div class="pl-title">
        <div class="text">评论</div>
      </div>
      <div class="pl-content no-pl" v-if="!m3u8">
        <div class="line-box"></div>
        <div class="text">暂无评论！</div>
      </div>
      <div class="pl-content" v-if="m3u8">
        <div class="item" v-for="(item, index) in plArr" :key="index">
          <div class="key">{{ item.plr.nickNames }}：</div>
          <div class="value" :class="item.color">{{ item.plnr.plxx }}</div>
        </div>
        <div id="resultDrag"></div>
      </div>
      <div class="send-pl">
        <Input class="pl-input" v-model="word" placeholder="说点儿什么..." @on-enter="sendWord" autocomplete="off">
        <div class="send-btn" slot="append" @click="sendWord">发送</div>
        </Input>
      </div>
    </div>
    <Modal v-model="introVisible" class-name="coin-modal">
      <p class="coin-title">微币规则说明</p>
      <p class="coin-item">1. 当前用来打赏主播的微币与人民币兑换规则为1:10。</p>
      <p class="coin-item">2. 10微币等于1元。</p>
      <p class="coin-item">3. 主播与平台以50%分成比例分配。</p>
      <p class="coin-item">4. 除了充值还可以进行每日任务打卡进行赚微币。</p>
      <p class="coin-title" style="margin-top: 25px;">怎么赚微币？</p>
      <p class="coin-item">1. 每位用户注册登录即可赠送1000微币。</p>
      <p class="coin-item">2. 每评论一次可赚取100微币。</p>
      <p class="coin-item">3. 每日签到首次登录即可赠送200微币。</p>
      <p class="coin-item">4. 每日赚取微币不可超过5000微币。</p>
      <p class="coin-item">5. 当前微币数据次日更新。</p>
      <p class="coin-tip">*以上活动最终解释权归微乐播科技所有</p>
      <div class="recharge" @click="rechargeVisible = true">充值</div>
    </Modal>
    <Modal v-model="giftVisible" class-name="gift-modal">
      <p class="gift-title">确定打赏？</p>
      <p class="gift-item">将消耗您帐户{{ coin }}微币，确认打赏给主播吗？</p>
      <p class="gift-btn">
        <Button @click="giftVisible = false">取消</Button>
        <Button type="primary" @click="toGift">确认</Button>
      </p>
    </Modal>
    <Modal v-model="rechargeVisible" class-name="gift-modal">
      <p class="gift-title">充值</p>
      <p class="gift-item recharge-item">
        <span class="key"></span>
        <span class="value">当前系统维护中，充值暂不可用。请等待升级完毕后再试。</span>
      </p>
      <p class="gift-btn">
        <Button type="primary" @click="rechargeVisible = false">确认</Button>
      </p>
    </Modal>
  </div>
</template>
<script>
export default {
  name: 'liveDetail',
  data() {
    return {
      playerId: 'liveVideo',
      player: '',
      m3u8: '', // 播放源地址
      plArr: [],
      colorArr: [],
      plxxs: [
        { xh: 1, plxx: '大佬：随便画画，没有技巧' },
        { xh: 2, plxx: '歪酱，这幅画要上色吗' },
        { xh: 3, plxx: '一群人在聊天' },
        { xh: 4, plxx: '在学习画画技巧' },
        { xh: 5, plxx: '无他，维手熟尔' },
        { xh: 6, plxx: '摸鱼瞎画' },
        { xh: 7, plxx: '方块君永远的神' },
        { xh: 8, plxx: '海绵宝宝：你没有礼貌的吗' },
        { xh: 9, plxx: '我听不到啊' },
        { xh: 10, plxx: '说明你麦收音好' },
        { xh: 11, plxx: '感觉电话亭好多' },
        { xh: 12, plxx: '多平台直播咩' },
        { xh: 13, plxx: 'up擅长平涂场景' },
        { xh: 14, plxx: '期待漫画新一话' },
        { xh: 15, plxx: '8万收藏' },
        { xh: 16, plxx: '这是哪里' },
        { xh: 17, plxx: '我老家四川的' },
        { xh: 18, plxx: '点歌 凉凉' },
        { xh: 19, plxx: '没有蚊子么' },
        { xh: 20, plxx: '欢迎我自己' },
        { xh: 21, plxx: '滴滴滴！打卡成功' },
        { xh: 22, plxx: '日落日出时间请看小地图' },
        { xh: 23, plxx: '有什么问题可以直接私聊UP！' },
        { xh: 24, plxx: '今天有点嗨吖！' },
        { xh: 25, plxx: '哦哦哦 谢谢解答' },
        { xh: 26, plxx: '是王企鹅哟' },
        { xh: 27, plxx: '黄脑袋的企鹅是什么品种' },
        { xh: 28, plxx: '嗨起来～' },
        { xh: 29, plxx: '他们关系好好' },
        { xh: 30, plxx: '玩了一早上也该累了' },
        { xh: 31, plxx: '玩累了，睡了' },
        { xh: 32, plxx: '哎哟，出来了' },
        { xh: 33, plxx: '吃饭去了拜拜' },
        { xh: 34, plxx: '下次你帮我拆吧' },
        { xh: 35, plxx: '好听' },
        { xh: 36, plxx: '直播不易，欢迎打赏' },
        { xh: 37, plxx: '不多说了，我点佛跳墙' },
        { xh: 38, plxx: '铁凶最近听那个那年年少没' },
        { xh: 39, plxx: '这是禁止的画面' },
        { xh: 40, plxx: '汤圆像极了下班回家的我' },
        { xh: 41, plxx: '居然同时都在睡' },
        { xh: 42, plxx: '签到签到' },
        { xh: 43, plxx: '有声音，但往往它会忽略掉' },
        { xh: 44, plxx: '热起来它会自动开启来' },
        { xh: 45, plxx: '我放弃治疗了' },
        { xh: 46, plxx: '1660s和2060哪个好' },
        { xh: 47, plxx: '现装机1万能拿个什么配置' },
        { xh: 48, plxx: 'ISS位置' },
        { xh: 49, plxx: '这是直播吗？' },
        { xh: 50, plxx: '勉强能看懂' },
        { xh: 51, plxx: '我还能学' },
        { xh: 52, plxx: '惨了，我绝望了' },
        { xh: 53, plxx: '疯狂逃窜，赶紧跑' },
        { xh: 54, plxx: '排队上称' },
        { xh: 55, plxx: '王企鹅在左下角' },
        { xh: 56, plxx: '为啥不给大哥称体重' },
        { xh: 57, plxx: '我要飞的更高' },
        { xh: 58, plxx: '猛男必备' },
        { xh: 59, plxx: '东道主说话就是不一样' },
        { xh: 60, plxx: 'AI VS 大神' },
        { xh: 61, plxx: '彩虹几点出来的' },
        { xh: 62, plxx: '封面好看' },
        { xh: 63, plxx: '天空瓦蓝蓝的' },
        { xh: 64, plxx: '刚起步就停车' },
        { xh: 65, plxx: '只有天空的色彩还能将就' },
        { xh: 66, plxx: '思念成沙终成河' },
        { xh: 67, plxx: '好大一坨机器' },
        { xh: 68, plxx: '穿越冰川？' },
        { xh: 69, plxx: '专业' },
        { xh: 70, plxx: '你可以放大一下哈哈哈哈' },
        { xh: 71, plxx: '我都知道' },
        { xh: 72, plxx: '饼干是个笨蛋' },
        { xh: 73, plxx: '一个盗国之战的内容' },
        { xh: 74, plxx: '又开始只狼，我喜欢' },
        { xh: 75, plxx: '这是鬼武者吗' },
        { xh: 76, plxx: '粉丝牌怎么拿' },
        { xh: 77, plxx: '发表情行吗' },
        { xh: 78, plxx: '发了你又不理' },
        { xh: 79, plxx: '啥时候有空带带我' },
        { xh: 80, plxx: '直接扎针，扎了就起来' },
        { xh: 81, plxx: '建睡袋蓝色和黄色有什么区别' },
        { xh: 82, plxx: '资金礼盒' },
        { xh: 83, plxx: '先去卖了吧' },
        { xh: 84, plxx: '谁叫你乱跑' },
        { xh: 85, plxx: '有点好玩' },
        { xh: 86, plxx: '好突然啊' },
        { xh: 87, plxx: '技能变多了' },
        { xh: 88, plxx: '正式版感觉怎么样' },
        { xh: 89, plxx: '可怜的阿毛' },
        { xh: 90, plxx: '蓝条是护盾吗' },
        { xh: 91, plxx: '要渡劫吗' },
        { xh: 92, plxx: '开视野的' },
        { xh: 93, plxx: '失眠患者的福音' },
        { xh: 94, plxx: '适合午睡' },
        { xh: 95, plxx: '这就是他的风格' },
        { xh: 96, plxx: '我继续忙，你们继续' },
        { xh: 97, plxx: '前康复运动' },
        { xh: 98, plxx: '老头环啊' },
        { xh: 99, plxx: '打卡打卡' },
        { xh: 100, plxx: '哎呀！太漂亮了！！！' },
        { xh: 101, plxx: '比我的好看多了。。。' },
        { xh: 102, plxx: '拜见大佬' },
        { xh: 103, plxx: '我来挖~我来打卡啦' },
        { xh: 104, plxx: '大人时代变了' },
        { xh: 105, plxx: '有坑位吗有坑位吗？' },
        { xh: 106, plxx: '兄弟们评论刷起来' },
        { xh: 107, plxx: '真的很热闹啊' },
        { xh: 108, plxx: '等哈上课去了' },
        { xh: 109, plxx: '回答是肯定会卡' },
        { xh: 110, plxx: '6666666666666' },
        { xh: 111, plxx: '老铁，这里等你呢' },
        { xh: 112, plxx: '当我打出问号，感觉你有问题' },
        { xh: 113, plxx: '小日子过得不错的。。。本人' },
        { xh: 114, plxx: '顶起来顶起来' },
        { xh: 115, plxx: '真的好看哎' },
        { xh: 116, plxx: '一起加油' },
        { xh: 117, plxx: 'yyds' },
        { xh: 118, plxx: '都注意我要变形了' },
        { xh: 119, plxx: '跟了跟了' },
        { xh: 120, plxx: '一群水逼' },
      ],
      nickNames: [
        { xh: 1, nickNames: '英雄在洗澡' },
        { xh: 2, nickNames: '阿莱克修斯' },
        { xh: 3, nickNames: '蕾娜' },
        { xh: 4, nickNames: '大海里的胖子' },
        { xh: 5, nickNames: '杂羽鸟' },
        { xh: 6, nickNames: '桑桑德系诺' },
        { xh: 7, nickNames: '勾魂摄魄de林一星' },
        { xh: 8, nickNames: '空待' },
        { xh: 9, nickNames: '找抽的猪' },
        { xh: 10, nickNames: '答应我别当一只舔狗' },
        { xh: 11, nickNames: '寻梦游仙' },
        { xh: 12, nickNames: '一只雨伞巴丹' },
        { xh: 13, nickNames: "闻木樨香'满目星河" },
        { xh: 14, nickNames: '夹夹不露根' },
        { xh: 15, nickNames: '酒囊饭袋的岑菲菲' },
        { xh: 16, nickNames: '你喜欢凤梨么' },
        { xh: 17, nickNames: '计算机原理' },
        { xh: 18, nickNames: '雨宫天' },
        { xh: 19, nickNames: 'SKY丶吻印' },
        { xh: 20, nickNames: '特特丶' },
        { xh: 21, nickNames: '卡哇伊波波' },
        { xh: 22, nickNames: '野蛮的油条' },
        { xh: 23, nickNames: 'JasonCitrus' },
        { xh: 24, nickNames: 'flashfox4' },
        { xh: 25, nickNames: '凸--_--凸' },
        { xh: 26, nickNames: '天允山下向问天' },
        { xh: 27, nickNames: 'Robokent' },
        { xh: 28, nickNames: 'Flandre Alcard' },
        { xh: 29, nickNames: '腦殘最討厭了' },
        { xh: 30, nickNames: 'pokoo' },
        { xh: 31, nickNames: '小小小白白' },
        { xh: 32, nickNames: '画面中的这名男子' },
        { xh: 33, nickNames: 'NNK的蓝钻' },
        { xh: 34, nickNames: 'OST好评' },
        { xh: 35, nickNames: '小猪公社' },
        { xh: 36, nickNames: '流氓电台' },
        { xh: 37, nickNames: '猴山红名' },
        { xh: 38, nickNames: '索利达尔丶群星之怒' },
        { xh: 39, nickNames: '蓝色的点心' },
        { xh: 40, nickNames: '传播绿能量' },
        { xh: 41, nickNames: '流水俟无痕' },
        { xh: 42, nickNames: '沙砂鲨莎纱了' },
        { xh: 43, nickNames: '爆炒牛肉' },
        { xh: 44, nickNames: '3000分的大神' },
        { xh: 45, nickNames: 'wangzi940' },
        { xh: 46, nickNames: '大牙一顿能吃三碗饭半张饼一碗汤' },
        { xh: 47, nickNames: '安菲力基斯罗博西雅图华盛顿祖' },
        { xh: 48, nickNames: 'Tryharder.' },
        { xh: 49, nickNames: '不可能OT' },
        { xh: 50, nickNames: '老子是图书馆整理员' },
        { xh: 51, nickNames: '游弋玩玩' },
        { xh: 52, nickNames: 'mayday' },
        { xh: 53, nickNames: '昆西酱0v0' },
        { xh: 54, nickNames: '见朕骑妓' },
        { xh: 55, nickNames: '盐酸微甜' },
        { xh: 56, nickNames: '那一个人v' },
        { xh: 57, nickNames: '天国的节操君' },
        { xh: 58, nickNames: '已是孤独' },
        { xh: 59, nickNames: '狭海之彼' },
        { xh: 60, nickNames: '快扶我起来我还能吃' },
        { xh: 61, nickNames: '鉴赏岁月' },
        { xh: 62, nickNames: '面包发霉' },
        { xh: 63, nickNames: '遥かなる苍' },
        { xh: 64, nickNames: '晓月大魔王' },
        { xh: 65, nickNames: '一捅喵扑的叫兽' },
        { xh: 66, nickNames: '不忘初心' },
        { xh: 67, nickNames: '蒂德利喵' },
        { xh: 68, nickNames: '风中鲨鱼' },
        { xh: 69, nickNames: '戢武王玉辭心' },
        { xh: 70, nickNames: '我是小学生我威武自豪' },
        { xh: 71, nickNames: '平凡的人类' },
        { xh: 72, nickNames: '贫道湿了' },
        { xh: 73, nickNames: '叮当の心情' },
        { xh: 74, nickNames: '无业の游民' },
        { xh: 75, nickNames: '不过想要个音乐名' },
        { xh: 76, nickNames: '是那个不是这个' },
        { xh: 77, nickNames: '不愿透露姓名的查尔斯郭ㅤ' },
        { xh: 78, nickNames: '晏采子' },
        { xh: 79, nickNames: '老阴啄小鸡' },
        { xh: 80, nickNames: '有梦想的咸鱼1发' },
        { xh: 81, nickNames: '生涯无悔二次元' },
        { xh: 82, nickNames: '银漪' },
        { xh: 83, nickNames: '我可能是个小帅' },
        { xh: 84, nickNames: '万古文章有坦途の康友文' },
        { xh: 85, nickNames: '叶落吱秋意' },
        { xh: 86, nickNames: '云中菌' },
        { xh: 87, nickNames: '只有三秒钟耐心的家伙' },
        { xh: 88, nickNames: '起名字特别特别特别特别的麻烦' },
        { xh: 89, nickNames: '蒙塔基的钢蛋a' },
        { xh: 90, nickNames: '谁动了凡心' },
        { xh: 91, nickNames: '杰克归来' },
        { xh: 92, nickNames: '原来是橘猫啊' },
        { xh: 93, nickNames: '獒骑士的触手' },
        { xh: 94, nickNames: '伊斯坎达尔买买提' },
        { xh: 95, nickNames: '长被花牵不自胜の翟维' },
        { xh: 96, nickNames: '苟丽国佳盛世矣七音豁府比驱之' },
        { xh: 97, nickNames: '星河巫妖' },
        { xh: 98, nickNames: '我的故乡是地球' },
        { xh: 100, nickNames: '游客1' },
        { xh: 101, nickNames: '游客2' },
        { xh: 102, nickNames: '游客3' },
        { xh: 103, nickNames: '游客4' },
        { xh: 104, nickNames: '游客5' },
        { xh: 105, nickNames: '游客6' },
        { xh: 106, nickNames: '游客7' },
        { xh: 107, nickNames: '游客8' },
        { xh: 108, nickNames: '游客9' },
        { xh: 109, nickNames: '游客10' },
        { xh: 110, nickNames: '游客11' },
        { xh: 111, nickNames: '游客12' },
        { xh: 112, nickNames: '游客13' },
        { xh: 113, nickNames: '游客14' },
        { xh: 114, nickNames: '游客15' },
        { xh: 115, nickNames: '游客16' },
        { xh: 116, nickNames: '游客17' },
        { xh: 117, nickNames: '游客18' },
        { xh: 118, nickNames: '游客19' },
        { xh: 119, nickNames: '游客20' },
        { xh: 120, nickNames: '游客21' },
      ],
      liveinfo: {},
      word: '',
      visitor: 0, // 游客数量
      introVisible: false, // 微币规则说明
      giftVisible: false, // 礼物弹窗
      coin: 0,
      user: {},
      rechargeVisible: false,
      teenage: false,
    }
  },
  created() {
    this.teenage = sessionStorage.getItem('isCheck') === 1
    this.user = JSON.parse(sessionStorage.getItem('userinfo'))
    this.liveinfo = JSON.parse(sessionStorage.getItem('detail'))
    this.m3u8 = this.liveinfo.url
    this.radomPlTotal()
    let timer2 = setTimeout(() => {
      clearTimeout(timer2)
      timer2 = null
      document.getElementById('resultDrag').scrollIntoView()
    }, 50)
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.m3u8) {
        await this.initTcPlayer()
      }
    })
  },
  computed: {
    xb() {
      return this.$store.state.money.xb
    },
  },
  methods: {
    initTcPlayer() {
      this.player = new window.TcPlayer('video-container', {
        // rtmp: this.$rtmpPlayUrl,
        // flv: this.$flvPlayUrl,
        // m3u8: isMobile ? this.$m3u8PlayUrl : this.$flvPlayUrl,
        m3u8: this.m3u8,
        autoplay: true,
        fixAudioTimestampGap: false,
        poster: this.$coverUrl,
        live: true,
        loop: true,
        continuePlay: true, // 是否连续播放
        fullscreen: false,
        listener: (msg) => {
          /**
           * 回调数据type值
           * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#Media_Events
           */
          // console.log(this.player)

          if (msg.type === 'loadedmetadata') {
            if (!this.player.playing()) {
              this.player.mute(true)
              this.player.currentTime(Math.random() * this.player.duration())
              setTimeout(() => {
                this.player.play()
              }, 0)
              //   this.player.mute(false)
            }
            // this.$toast('直播配置错误')
            // this.tcplayerErrorTimer = window.setTimeout(function () {
            // this.player.paly() // 进行重连
            // }, 5000)
          }
        },
      })
    },
    radomPlTotal() {
      if (this.m3u8) {
        const count = 120 //
        const a = []
        for (let i = 0; i < count; i++) {
          a[i] = i + 1
        }
        a.sort(function () {
          return 0.5 - Math.random()
        })
        this.radomPlxx(a[0])
      }
    },
    async radomPlxx(count) {
      if (this.m3u8) {
        const a = []
        for (let i = 0; i < count; i++) {
          a[i] = i + 1
        }
        a.sort(function () {
          return 0.5 - Math.random()
        })
        await this.radomColor(a.length)
        // console.log(this.colorArr)
        for (let r = 0; r < a.length; r++) {
          for (let c = 0; c < this.colorArr.length; c++) {
            if (a[r] === this.colorArr[c]) {
              const ys = a[r] % 3
              this.plArr.push({
                plr: this.nickNames[a[r]],
                plnr: this.plxxs[a[r]],
                color: ys === 1 ? 'red' : ys === 2 ? 'yellow' : 'gray',
              })
              break
            }
            if (c === this.colorArr.length - 1) {
              this.plArr.push({
                plr: this.nickNames[a[r]],
                plnr: this.plxxs[a[r]],
              })
            }
          }
        }
        this.plArr.forEach((item) => {
          if (item.plr.nickNames.indexOf('游客') > 0) {
            this.visitor = this.visitor + 1
          }
        })
        if (this.visitor === 0) {
          this.visitor = 1
        }
      }
    },
    radomColor(count) {
      if (count > 2) {
        count = (count / 2).toFixed(0)
      }
      const a = []
      for (let i = 0; i < count; i++) {
        a[i] = i + 1
      }
      a.sort(function () {
        return 0.5 - Math.random()
      })
      this.colorArr = a
    },
    sendWord() {
      const user = JSON.parse(sessionStorage.getItem('userinfo'))
      if (!this.word.trim()) {
        return false
      }
      if (user) {
        this.plArr.push({
          plnr: {
            plxx: this.word,
            xh: this.plArr.length + 1,
          },
          plr: {
            nickNames: `${user.name}`,
            xh: this.plArr.length + 1,
          },
        })
      } else {
        this.plArr.push({
          plnr: {
            plxx: this.word,
            xh: this.plArr.length + 1,
          },
          plr: {
            nickNames: `游客${this.visitor}`,
            xh: this.plArr.length + 1,
          },
        })
      }
      this.word = ''
      let timer2 = setTimeout(() => {
        clearTimeout(timer2)
        timer2 = null
        document.getElementById('resultDrag').scrollIntoView()
      }, 50)
    },
    getXbGz() {
      this.introVisible = true
    },
    reward(num) {
      const user = JSON.parse(this.$getSession('userinfo'))
      if (user) {
        if (this.teenage === true) {
          this.$Message.warning('未成年人不支持打赏~~~')
        } else {
          this.coin = num
          this.giftVisible = true
        }
      } else {
        document.getElementById('login').click()
      }
    },
    toGift() {
      const sysl = (this.$store.state.money.xb - this.coin).toFixed(0)
      if (sysl < 0) {
        this.$Message.warning('您的微币不足哦~~~')
      } else {
        this.$Message.success('打赏成功~~~')
        this.$store.dispatch('getXb', sysl)
        this.giftVisible = false
      }
    },
  },
}
</script>
